<template>
  <div>
    <div class="header-text">
      <h1>{{ $t("termsOfUse.title") }}</h1>
    </div>
    <section class="container content">
      <div class="">
        <h1>{{ $t("termsOfUse.glovelerTermsOfUse") }}</h1>
        <p>
          {{ $t("termsOfUse.glovelerTermsOfUseDescription") }}
        </p>
      </div>
    </section>
    <section class="container content">
      <div class="mt-5">
        <h2>{{ $t("termsOfUse.introduction") }}</h2>
        <p>{{ $t("termsOfUse.lastUpdate") }}</p>
        <p>
          {{ $t("termsOfUse.body") }}
        </p>
        <p>{{ $t("termsOfUse.tableOfContents") }}</p>
        <ul>
          <li>{{ $t("termsOfUse.whatIsTerms") }}</li>
          <li>{{ $t("termsOfUse.whyTerms") }}</li>
          <li>{{ $t("termsOfUse.mostCommonTerms") }}</li>
          <li>{{ $t("termsOfUse.whereToDisplayTerms") }}</li>
          <li>{{ $t("termsOfUse.enforceTerms") }}</li>
          <li>{{ $t("termsOfUse.termsExample") }}</li>
          <li>{{ $t("termsOfUse.whyTermsGloveler") }}</li>
          <li>{{ $t("termsOfUse.termsFaq") }}</li>
          <li>{{ $t("termsOfUse.termsSummary") }}</li>
        </ul>
      </div>
    </section>
    <section class="container content">
      <h2>1. {{ $t("termsOfUse.whatIsTerms") }}</h2>
      <p>
        {{ $t("termsOfUse.whatIsTermsContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>2. {{ $t("termsOfUse.whyTerms") }}</h2>
      <p>
        {{ $t("termsOfUse.whyTermsContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>3. {{ $t("termsOfUse.mostCommonTerms") }}</h2>
      <p>
        {{ $t("termsOfUse.mostCommonTermsContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>4. {{ $t("termsOfUse.whereToDisplayTerms") }}</h2>
      <p>
        {{ $t("termsOfUse.whereToDisplayTermsContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>5. {{ $t("termsOfUse.enforceTerms") }}</h2>
      <p>
        {{ $t("termsOfUse.enforceTermsContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>6. {{ $t("termsOfUse.termsExample") }}</h2>
      <p>
        {{ $t("termsOfUse.termsExampleContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>7. {{ $t("termsOfUse.whyTermsGloveler") }}</h2>
      <p>
        {{ $t("termsOfUse.whyTermsGlovelerContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>8. {{ $t("termsOfUse.termsFaq") }}</h2>
      <p>
        {{ $t("termsOfUse.termsFaqContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>9. {{ $t("termsOfUse.termsSummary") }}</h2>
      <p>
        {{ $t("termsOfUse.termsSummaryContent") }}
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  components: {},
};
</script>

<style scoped>
.header-text {
  padding: 30px 0 60px 0;
  color: #fff;
  background: #ff762b;
  color: white;
  text-align: center;
}

.header-text h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 45px;
}

.content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content h1 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #444444;
}
.content h2 {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #444444;
}
.content p {
  color: #969696;
}
.mt-10 {
  margin-top: 90px;
}
ul {
  margin: 0;
  padding: 0;
  margin-left: 16px;
}
ul li {
  list-style: decimal;
  color: #ff762b;
  padding: 5px 10px;
}
@media only screen and (max-width: 500px) {
  .banner {
    height: 30vh;
  }

  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .banner-text {
    width: 100%;
  }

  .content h1 {
    font-size: 28px;
  }

  .content h2 {
    font-size: 23px;
  }

  .header-text h1 {
    font-size: 30px;
  }

  .mt-10 {
    margin-top: 35px;
  }
}
</style>
